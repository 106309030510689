<template>
  <main>
    <section class="contact-view">
      <div class="title">
        <h1>Kontakt</h1>
      </div>
    </section>
    <ContactForm/>
    <section v-scrollanimation class="contact-info mt-68 p-84">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6">
            <section class="adress">
              <h5>Adresa:</h5>
              <p>GELKAM, s.r.o.</p>
              <p>
                <a href="https://goo.gl/maps/tMAkWtWYpqQusnwm9" title="Adresa" target="_blank" ref="noopener noreferrer">Slovenská 13, 056 01 Gelnica</a>
              </p>
              <p>IČO: 36 601 055</p>
              <p>IČ DPH: SK2022104106</p>
              <h5>Tel. kontakt:</h5>
              <p>
                <a href="tel:0911249267">0911 249 267</a>
              </p>
              <p>
                <a href="tel:0534411493">053/44 11 493</a>
              </p>
              <p>
                <a href="tel:0903214208">0903 214 208</a>
              </p>
              <p>
                <a href="tel:0904397269">0904 397 269</a>
              </p>
              <h5>Email:</h5>
              <p>
                <a href="mailto:gelkamsro@gmail.com" title="Poslať email">gelkamsro@gmail.com</a>
              </p>
              <a href="https://www.facebook.com/kamenarstvogelkam" title="Facebook" target="_blank" rel="noopener noreferrer">
                <fbIcon/>
              </a>
              <h5>Spolupracujeme s:</h5>
              <p>
                <a href="https://vonave-sviecky.sk" title="Voňavé sviečky" target="_blank">Voňavé sviečky</a>
              </p>
              <a href="https://www.facebook.com/Voňavé-sviečky-108923228044345" target="_blank" title="Voňavé sviečky" ref="noopener noreferrer"><fbIcon/></a>
            </section>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6">
            <section class="openings">
              <h5>Otváracie hodiny:</h5>
              <div class="days">
                <p>Pondelok:</p>
                <span>07:00 - 16:00</span>
              </div>
              <div class="days">
                <p>Utorok:</p>
                <span>07:00 - 16:00</span>
              </div>
              <div class="days">
                <p>Streda:</p>
                <span>07:00 - 16:00</span>
              </div>
              <div class="days">
                <p>Štvrtok:</p>
                <span>07:00 - 16:00</span>
              </div>
              <div class="days">
                <p>Piatok:</p>
                <span>07:00 - 16:00</span>
              </div>
              <div class="days">
                <p>Sobota:</p>
                <span>po tel. dohode</span>
              </div>
              <div class="days">
                <p>Nedeľa:</p>
                <span>po tel. dohode</span>
              </div>
            </section>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-12">
            <section class="map">
              <iframe title="Adresa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1103.7803097635265!2d20.934319284018148!3d48.853504095480005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ef9201513ed9f%3A0x7b337e68166a0243!2sSlovensk%C3%A1%2013%2C%20056%2001%20Gelnica!5e0!3m2!1ssk!2ssk!4v1630483015433!5m2!1ssk!2ssk" width="100%" height="364px" ></iframe>
            </section>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ContactForm from '../components/ContactForm.vue'
import fbIcon from '../assets/icons/facebook-square-brands.svg'
export default {
  name: 'Contact',
  components: { 
    ContactForm, fbIcon },
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.contact-view{
  @include overlay;
  @include views-title;
}

.contact-info{
  text-align: left;
  @media #{$very_small}{
    text-align: center;
  }
  h5{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px !important;
  }
  p{
    font-size: 16px;
    margin-bottom: 12px !important;
  }
  a{
    &:hover{
      color: $secondary !important;
      transition: 0.35s ease;
    }
    &:focus{
      color: $secondary !important;
      transition: 0.35s ease;
    }
  }
  svg{
    width: auto !important;
    height: 20px !important;
    cursor: pointer;
    &:hover{
      color: $secondary;
      transition: 0.35s ease;
    }
    &:focus{
      color: $secondary;
      transition: 0.35s ease;
    }
  }
  .openings{
    .days{
      display: flex;
      justify-content: space-between;
      @media #{$very_small}{
        justify-content: space-evenly;
      }
      span{
        margin-right: 68px;
        @media #{$mobile}{
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
