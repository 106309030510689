<template>
  	<section v-scrollanimation class="contact-form-section p-84 mt-68" >
	  	<div class="container-fluid">
		 	 <div class="row">
			  	<div class="col-lg-12">
				  	<div class="contact-form">
						<form class="form-inputs" @submit.prevent="sendEmail">
							<h2>Kontaktný formulár</h2>
							<div class="row">
								<div class="col-lg-6 col-md-6">
									<p>Meno a priezvisko</p>
									<label for="user_name">
										<input id="user_name" type="text" name="user_name" v-model="name" placeholder="Meno a priezvisko" :class="{ 'is-invalid': submitted && $v.name.$error }">
										<div v-if="submitted && !$v.name.required" class="invalid-feedback">Zadajte meno a priezvisko</div>
									</label>
									<p>E-mailová adresa</p>
									<label for="user_email">
										<input id="user_email" type="email" name="user_email" v-model="email" placeholder="E-mailová adresa" :class="{ 'is-invalid': submitted && $v.email.$error }">
										<div v-if="submitted && $v.email.$error" class="invalid-feedback">
										<span v-if="!$v.email.required">Zadajte e-mail</span>
										<span v-if="!$v.email.email">Zadajte platný e-mail</span>
										</div>
									</label>
									<p>Predmet</p>
									<label for="user_subject">
										<input id="user_subject" type="subject" name="user_subject" v-model="subject" placeholder="Predmet" :class="{ 'is-invalid': submitted && $v.subject.$error }">
										<div v-if="submitted && !$v.subject.required" class="invalid-feedback">Zadajte predmet</div>
									</label>
								</div>
								<div class="col-lg-6 col-md-6">
									<p>Správa</p>
									<label for="message">
										<textarea id="message"  name="message" v-model="message" placeholder="Vaša správa..." :class="{ 'is-invalid': submitted && $v.message.$error }"></textarea>
										<div v-if="submitted && !$v.message.required" class="invalid-feedback">Napíšte správu</div>
									</label>
								</div>
                                <div class="col-lg-12">
									<label for="checkbox">
										<ul>
											<li>
												<input id="checkbox" style="width:auto" type="checkbox" name="checkbox" v-model="checkbox" :class="{ 'is-invalid': submitted && $v.checkbox.$error }">
											</li>
											<li>
												<p>Odoslaním formulára súhlasíte so spracovaním <a href="https://kamenarstvo-gelkam.sk/GDPR.pdf" target="_blank" title="GDPR">osobných údajov.</a></p>
											</li>
										</ul>
										<div v-if="submitted && !$v.checkbox.required" class="invalid-feedback">Potvrďte súhlas so spracovaním osobných údajov.</div>
									</label>
								</div>
							</div>
							<div class="col-lg-12">
								<button type="submit">Odoslať správu</button>
							</div>
							<h6 class="green">{{response}}</h6>
							<h6 class="red">{{wrong}}</h6>
						</form>
				  	</div>
			  	</div>
		  	</div>
	  	</div>
  	</section>
</template>

<script>
import emailjs from 'emailjs-com';
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
	name: 'Contactform',
	data(){
    return{
      name: '',
      email: '',
      subject: '',
      message: '',
      checkbox: '',
      response: '',
      wrong: '',
      submitted: false
    }
  },
  validations: {
    name: {required},
    email: {required, email},
    subject: {required},
    message: {required},
    checkbox: {required},
  },
  methods: {
    sendEmail(e) {
      try {
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
       }
        emailjs.sendForm('service_yph493w', 'template_ly1cxm7', e.target,
        'user_UnLjwUCKDzafzntAlAbJI', {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message
        })
          this.response = 'Vaša správa bola odoslaná!'
          //Hide response
          setTimeout(() => this.response = '', 5000)
          // Reset form field
          this.name = ''
          this.email = ''
          this.subject = ''
          this.message = ''
          this.checkbox = ''

          //Clear validation
          this.submitted = false;
      } catch(error) {
        this.wrong = 'Vyskytla sa chyba, skúste to znovu!'
      }
    },
  }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.contact-form-section{
	color: $primary;
	text-align: left;
	h3{
		font-size: 30px;
		font-weight: 700;
	}
	.contact-form{
		.form-inputs{
			@media #{$xs} {
				text-align: center;
			}
            h2{
                @include section-header;
            }
			div{
				p{
					padding-left: 12px;
					margin-top: 12px;
					margin-bottom: 12px !important;
					@media #{$xs} {
						padding-left: 0px;
					}
                    a{
                        color: $secondary !important;
                        font-weight: 700;
                    }
				}
				label{
					width: 100%;
					input{
						width: 100%;
						border: 1px solid $primary;
						border-radius: 5px;
						padding: 12px 0px 12px 12px;
						@media #{$xs} {
							padding: 12px 0;
						}
					}
					textarea{
						width: 100%;
						height: 262px !important;
						border: 1px solid $primary;
						border-radius: 5px;
						padding: 12px 0px 0px 12px;
						@media #{$xs} {
							padding: 12px 0;
						}
					}
					ul{
							li{
								&:first-child{
									margin-right: 12px;
								}
								p{
									padding-left: 0px;
									margin-top: 0px;
								}
							}
						}
				}
				button{
					@include buttons;
                    color: $white;
					border: 1px solid $primary;
					outline: none;
					margin-top: 24px;
					margin-left: -15px;
				}
			}
			.green{
				color: green;
				}
			.red{
				color: red;
			}
		}
	}
}
::placeholder{
	font-style: italic;
	@media #{$xs} {
		text-align: center;
	}
}
.invalid-feedback{
    display: inline-block;
}
</style>